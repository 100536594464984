import {Suspense, useContext, useEffect, useRef} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import routes from 'utils/routes'
import PrivateRoute from 'components/PrivateRoute'
import PublicRoute from 'components/PublicRoute'
import {AuthContext} from 'contexts/AuthContext'
import {getItemFromStorage} from 'utils/storage'
import Loading from 'components/Loading'
import {useMutation} from 'react-query'
import {getMyProfile} from 'api'

function App() {
  const {state, dispatch} = useContext(AuthContext)

  const user = useRef(getItemFromStorage({name: 'user'}))

  useEffect(() => {
    const isAuth = user?.current ? true : false
    dispatch({...state, isAuth, user: user.current})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {mutate} = useMutation('myProfile', getMyProfile, {
    onSuccess: () => {},
  })

  useEffect(() => {
    const token = state?.user?.token
    const id = state?.user?.id
    if (token) {
      mutate(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  if (!state) return <Loading />

  return (
    <div className="App font-inter overflow-x-hidden">
      <BrowserRouter>
        <div className="app-content">
          <Routes>
            {routes.map((route, i) => {
              const key = `route-${i}`
              if (route.full) {
                return (
                  <Route
                    key={key}
                    path={route.path}
                    element={
                      <Suspense fallback={<Loading />}>
                        <route.component />
                      </Suspense>
                    }
                  />
                )
              }
              if (route.protected) {
                return (
                  <Route
                    key={key}
                    path={route.path}
                    element={
                      <PrivateRoute
                        isAuth={state?.isAuth}
                        path={route.path}
                        protect={route.protected}
                      >
                        <Suspense fallback={<Loading />}>
                          <route.component />
                        </Suspense>
                      </PrivateRoute>
                    }
                  />
                )
              } else {
                return (
                  <Route
                    key={key}
                    path={route.path}
                    element={
                      <PublicRoute path={route.path} isAuth={state?.isAuth}>
                        <Suspense fallback={<Loading />}>
                          <route.component />
                        </Suspense>
                      </PublicRoute>
                    }
                  />
                )
              }
            })}
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  )
}

export default App
