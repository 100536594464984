import {lazy} from 'react'

const LandingPage = lazy(async () => await import('pages/LandingPage'))
const GetStarted = lazy(async () => await import('pages/GetStarted'))
const Register = lazy(async () => await import('pages/Register'))
const Login = lazy(async () => await import('pages/Login'))
const VerifyEmail = lazy(async () => await import('pages/VerifyEmail'))
const ForgotPassword = lazy(async () => await import('pages/ForgotPassword'))
const ResetPassword = lazy(async () => await import('pages/ResetPassword'))
const SetupAccount = lazy(async () => await import('pages/SetupAccount'))
const SetupBusiness = lazy(async () => await import('pages/SetupBusiness'))
const SetupConsumer = lazy(async () => await import('pages/SetupConsumer'))
const SetupComleted = lazy(async () => await import('pages/SetupCompleted'))
const AuthVerify = lazy(async () => await import('pages/AuthVerify'))
const Profile = lazy(async () => await import('pages/Profile'))
const ProfilePublic = lazy(async () => await import('pages/ProfilePublic'))
const Jobs = lazy(async () => await import('pages/Jobs'))
const Classes = lazy(async () => await import('pages/Classes'))
const PublicJobPost = lazy(async () => await import('pages/PublicJobPost'))
const PublicClassPost = lazy(async () => await import('pages/PublicClassPost'))
const Credits = lazy(async () => await import('pages/Credits'))
const AddCredits = lazy(async () => await import('pages/AddCredits'))
const Checkout = lazy(async () => await import('pages/Checkout'))
const PremiumActivated = lazy(
  async () => await import('pages/PremiumActivated')
)
const NotFound = lazy(async () => await import('pages/NotFound'))
const PrivacyPolicy = lazy(async () => await import('pages/PrivacyPolicy'))

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    exact: true,
    component: LandingPage,
    protected: false,
  },
  {
    path: '/get-started',
    name: 'GetStarted',
    exact: true,
    component: GetStarted,
    protected: false,
  },
  {
    path: '/register',
    name: 'Register',
    exact: true,
    component: Register,
    protected: false,
  },
  {
    path: '/login',
    name: 'Login',
    exact: false,
    component: Login,
    protected: false,
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    exact: true,
    component: VerifyEmail,
    protected: false,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    exact: true,
    component: ForgotPassword,
    protected: false,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    exact: false,
    component: ResetPassword,
    protected: false,
  },
  {
    path: '/setup-business',
    name: 'SetupBusiness',
    exact: true,
    component: SetupBusiness,
    protected: true,
  },
  {
    path: '/setup-account',
    name: 'SetupAccount',
    exact: true,
    component: SetupAccount,
    protected: true,
  },
  {
    path: '/setup-consumer',
    name: 'SetupConsumer',
    exact: true,
    component: SetupConsumer,
    protected: true,
  },
  {
    path: '/setup-completed',
    name: 'SetupCompleted',
    exact: true,
    component: SetupComleted,
    protected: true,
  },
  {
    path: '/auth/callback',
    name: 'AuthVerify',
    exact: true,
    component: AuthVerify,
    protected: true,
  },
  {
    path: '/profile',
    name: 'Profile',
    exact: true,
    component: Profile,
    protected: true,
  },
  {
    path: '/profile/:id',
    name: 'Profile Public',
    exact: false,
    component: ProfilePublic,
    protected: false,
  },
  {
    path: '/job/:jobId',
    name: 'JobPost',
    exact: false,
    component: Profile,
    protected: true,
  },
  {
    path: '/job/:jobId/preview',
    name: 'PreviewJobPost',
    exact: false,
    component: PublicJobPost,
    protected: false,
  },
  {
    path: '/create-job-post/:jobId',
    name: 'CreateJobPost',
    exact: false,
    component: Profile,
    protected: true,
  },
  {
    path: '/edit-job-post/:jobId',
    name: 'EditJobPost',
    exact: false,
    component: Profile,
    protected: true,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    exact: false,
    component: Jobs,
    protected: true,
  },
  {
    path: '/profiles',
    name: 'Profiles',
    exact: false,
    component: Profile,
    protected: true,
  },
  {
    path: '/class/:classId',
    name: 'ClassPost',
    exact: false,
    component: Profile,
    protected: true,
  },
  {
    /*
    path: '/class/:classId/preview',
    name: 'PreviewClassPost',
    exact: false,
    component: PublicClassPost,
    protected: false,
  */
  },
  {
    path: '/edit-class-post/:classId',
    name: 'EditClassPost',
    exact: false,
    component: Profile,
    protected: true,
  },
  {
    path: '/classes',
    name: 'Classes',
    exact: false,
    component: Classes,
    protected: true,
  },
  process.env.REACT_APP_ENABLE_PREMIUM === 'true'
    ? {
        path: '/credits',
        name: 'Credits',
        exact: true,
        component: Credits,
        protected: true,
      }
    : {},
  process.env.REACT_APP_ENABLE_PREMIUM === 'true'
    ? {
        path: '/add-credits',
        name: 'AddCredits',
        exact: true,
        component: AddCredits,
        protected: true,
      }
    : {},
  process.env.REACT_APP_ENABLE_PREMIUM === 'true'
    ? {
        path: '/checkout',
        name: 'Checkout',
        exact: true,
        component: Checkout,
        protected: true,
      }
    : {},
  process.env.REACT_APP_ENABLE_PREMIUM === 'true'
    ? {
        path: '/premium-activated',
        name: 'PremiumActivated',
        exact: true,
        component: PremiumActivated,
        protected: true,
      }
    : {},
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    exact: true,
    component: PrivacyPolicy,
    protected: false,
  },
  {
    path: '*',
    name: 'NotFound',
    exact: false,
    component: NotFound,
    protected: false,
  },
]

export default routes
