import React from 'react'

const Loading = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center flex-col">
      {/*<div className="font-canela text-lg text-slate-400">Loading...</div>*/}
    </div>
  )
}

export default Loading
