import React from 'react'
import ReactDOM from 'react-dom/client'
import {QueryClient, QueryClientProvider} from 'react-query'
import './index.scss'
import App from 'pages/App/App'
import {AuthProvider} from 'contexts/AuthContext'
import {ToastContainer} from 'react-toastify'
import {UserTypeViewProvider} from 'contexts/UserTypeViewContext'
import {TabsProvider} from 'contexts/TabsContext'
import {FacebookProvider} from 'react-facebook'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <QueryClientProvider client={queryClient}>
    <FacebookProvider appId={process.env.REACT_APP_FB_ID}>
      <TabsProvider>
        <AuthProvider>
          <UserTypeViewProvider>
            <App />
          </UserTypeViewProvider>
          <ToastContainer
            style={{minWidth: '350px'}}
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
            draggable
            progress={undefined}
            theme="colored"
          />
        </AuthProvider>
      </TabsProvider>
    </FacebookProvider>
  </QueryClientProvider>
)
