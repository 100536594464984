import {useState, createContext} from 'react'
import {getItemFromStorage} from 'utils/storage'

export const UserTypeViewContext = createContext(false)

export const UserTypeViewProvider = ({children}) => {
  const profile = getItemFromStorage({name: 'profile'})

  const [userTypeView, setUserTypeView] = useState(profile?.userType)

  return (
    <UserTypeViewContext.Provider
      value={{
        userTypeView,
        setUserTypeView,
      }}
    >
      {children}
    </UserTypeViewContext.Provider>
  )
}
