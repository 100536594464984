import {useState, createContext} from 'react'

export const AuthContext = createContext()

export const AuthProvider = ({children}) => {
  const [state, dispatch] = useState()
  const value = {state, dispatch}

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
