import React, {useContext, useEffect} from 'react'
import {Navigate, useLocation, useParams} from 'react-router-dom'
import {AuthContext} from 'contexts/AuthContext'

const PrivateRoute = ({children, isAuth, full, path, protect}) => {
  let location = useLocation()
  const {state} = useContext(AuthContext)
  const {jobId} = useParams()

  if (location?.pathname?.startsWith('/job/') && !state?.isAuth) {
    return <Navigate to={`/job/${jobId}/preview`} replace />
  } else if (!state?.isAuth) {
    return <Navigate to="/login" state={{from: location}} replace />
  }

  return children
}

export default PrivateRoute
